<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
        <p class="mb-2">We couldn't find what you were looking for.</p>
        <b-button variant="primary" class="mb-1 btn-sm-block" :to="homeRoute()">Back to Home</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import { getHomeRouteForLoggedInUser } from '@/auth/utils';

export default {
  components: {
    BButton,
  },
  methods: {
    homeRoute() {
      const permissions = JSON.parse(localStorage.getItem('$perm'));
      return getHomeRouteForLoggedInUser(permissions || null);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
